<template>
  <div>
    <head-layout
      :head-title="$t('OfflinedataCollection.name')+'日志'"
    >
    </head-layout>

    <div class="headers" style="display: flex">
      <!-- <el-input
        :placeholder="$t('cip.dc.KFDComission.name')"
        v-model="queryList.name"
        class="new-sino-input"
        size="mini"
        style="width: 200px"
      /> -->
      <div style="width: 200px;">
        <el-select v-model="queryList.execStatus" placeholder="请选择状态" clearable>
    <el-option
      v-for="item in execStatusOptions"
      :key="item.value"
      :label="item.label"
      :value="item.value"

      >
    </el-option>
  </el-select>
      </div>

      <el-button-group style="margin-left: 10px">
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-search"
          @click="onLoad"
        ></el-button>
        <el-button
          size="mini"
          icon="reset-refresh icon-refresh"
          @click="czFn"
        ></el-button>
      </el-button-group>
    </div>

    <grid-layout
      ref="gridLayout"
      :data-total="total"
      @page-size-change="handleSizeChange"
      @page-current-change="handleCurrentChange"
      @page-refresh-change="onLoad"
      :page="page"
      @gird-handle-select-click="handleSelectionChange"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"

      :searchColumns="searchColumns"
    >
      <template slot="execStatus" slot-scope="{ row }">
        {{
          row.execStatus == 1
            ? '失败'
            : row.execStatus == 0
            ? '成功'
            : row.execStatus
        }}
      </template>


    </grid-layout>

    <el-dialog
      :title="$t('cip.dc.DComission.tip')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>{{ dialogtext }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('cip.dc.DComission.false') }}</el-button>
        <el-button
          type="primary"
          @click="SureToExecute"

          >{{ $t('cip.dc.DComission.sure') }}</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('cip.dc.DComission.tip')"
      :visible.sync="dialogExecute"
      width="30%"
      :before-close="handleClose"
    >
      <p>
        {{ dialogtext }}
      </p>

      <el-date-picker
        v-model="ExecuteTime"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        :range-separator="$t('cip.dc.DComission.to')"
        :start-placeholder="$t('cip.dc.DComission.startdate')"
        :end-placeholder="$t('cip.dc.DComission.enddate')"
      >
      </el-date-picker>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogExecute = false">{{ $t('cip.dc.DComission.false') }}</el-button>
        <el-button type="primary" @click="SureToExecute" :loading="Executeloading">{{ $t('cip.dc.DComission.sure') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  tokenList,
  tokentUp,
  tokentDel,
  datSoSel,
  tokenInitcode,
  gatherdel,
} from "@/api/dataAcquisition/index";
import {
  getOfflinelog,
  DelOffline,
  stopOffline,
  zxOffline2,
} from "@/api/dataAcquisition/offline";
import { mapGetters } from "vuex";
import website from "@/config/website";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from "@/views/components/layout/form-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout,
  },
  data() {
    return {
      Executeloading: false,
      ExecuteTime: [], //执行时间
      dialogExecute: false,
      dialogtext: "",
      runState: "",
      btnType: "",
      dialogVisible: false,

      tableOptions: {
        customRowAddBtn: false,
        menu:false,
        column: [
          {
            label: '采集名称',
            prop: "collectName",
            cell: true,
          },
          {
            label: '执行时间',
            prop: "execTime",
            cell: true,

          },
          {
            label: '状态',
            prop: "execStatus",
            cell: true,
            slot: true,
          },


          {
            label: '执行结果',
            prop: "execResult",
            cell: true,

          },
        ],
      },
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      tableLoading: false,
      multipleSelection: [],
      tableData: [],
      total: 0,
      queryList: {
        query: {
          current: 1,
          size: 10,
        },
        collectType:2,
        collectId:this.$route.query.id,
        execStatus:'',
      },
      loading: false,

      execStatusOptions:[
        {label:'成功',value:0},
        {label:'失败',value:1},
      ]
    };
  },
  computed: {},
  methods: {



    onLoad(page, params = {}) {
      // this.queryList.collectId=this.$route.query.id
       this.loading = true;

      getOfflinelog(this.queryList).then((res) => {
        this.loading = false;
        this.loading = false;

        this.tableData = res.data.data.dataList;
        this.total = res.data.data.page.totalSize;
      });
    },
    getAutoCodeFn() {
      tokenInitcode().then((res) => {
        console.log(res);
        this.form.infCode = res.data.data;
      });
    },
    addFn() {
      this.$router.push({
        path: "/dataAcquisition/offline",
        query: {
          type: "add",
        },
      });
    },
    handleEdit(item) {
      this.$router.push({
        path: "/dataAcquisition/offline",
        query: {
          type: "edit",
          id: item.id,
        },
      });
    },
    delRow(e) {
      let ids = { id: e.id };

      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          DelOffline(ids).then((res) => {
            this.$message.success(this.$t('cip.dc.offlineDComission.delmsg'));
            this.onLoad();
          });
        })
        .catch(() => {});
    },
    deleteFn() {
      if (this.multipleSelection.length == 0) {
        this.$message.error(this.$t('cip.dc.offlineDComission.deltip'));
        return;
      }
      let ids = this.multipleSelection.map((item) => {
        return item.id;
      });
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          tokentDel(ids).then((res) => {
            this.$message.success(this.$t('cip.dc.offlineDComission.delmsg'));
            this.onLoad();
          });
        })
        .catch(() => {});
    },

    toExecute(item) {
      if (item.runState == 0) {
        this.dialogtext = this.$t('cip.dc.DComission.msg1');
        this.btnType = "execute";
      }
      if (item.runState == 1) {
        this.dialogtext = this.$t('cip.dc.DComission.msg2');
        this.btnType = "stop";
      }
      this.dialogVisible = true;
      this.ExecuteId = item.id;

      this.runState = item.runState;
    },

    toExecute2(item) {
      this.ExecuteTime = "";
      this.dialogtext = this.$t('cip.dc.DComission.runtime');
      this.btnType = "zx";
      this.dialogExecute = true;
      this.ExecuteId = item.id;
      this.runState = item.runState;
    },

    SureToExecute() {
      this.dialogVisible = false;

      if (this.btnType == "zx") {
        // if (this.ExecuteTime) {
          this.Executeloading = true;

          zxOffline2({
            id: this.ExecuteId,
            incrStartTime: this.ExecuteTime[0],
            incrEndTime: this.ExecuteTime[1],
          })
            .then((res) => {
               this.$message({
                message: this.$t('cip.dc.DComission.msg3'),
                type: "success",
              });
              this.onLoad();
              this.dialogExecute = false;
              this.Executeloading = false;

            })
            .catch((res) => {
              this.dialogExecute = false;
              this.Executeloading = false;
            });
        // } else {
        //   this.$message({
        //     message: "请选择执行时间！",
        //     type: "warning",
        //   });
        // }
      }

      if (this.btnType == "execute") {
        stopOffline({ id: this.ExecuteId, runState: "1" }).then((res) => {
          this.onLoad();
          this.$message({
            message: this.$t('cip.dc.DComission.msg4'),
            type: "success",
          });
        });
      }
      if (this.btnType == "stop") {
        stopOffline({ id: this.ExecuteId, runState: "0" }).then((res) => {
          this.onLoad();
          this.$message({
            message: this.$t('cip.dc.DComission.msg5'),
            type: "success",
          });
        });
      }
    },

    czFn() {
      this.queryList =  {
        query: {
          current: 1,
          size: 10,
        },
        collectType:2,
        collectId:this.$route.query.id,
        execStatus:'',
      },
      this.onLoad();
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },

    handleSizeChange(val) {
      this.page.pageSize = val.pageSize;
      this.queryList.query.current = 1;
      this.queryList.query.size = val.pageSize;
      this.onLoad();
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage;
      this.queryList.query.current = val.currentPage;
      this.onLoad();
    },
  },
  created() {
    this.onLoad();
  },

};
</script>
<style scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}
.headers ::v-deep .el-button {
  height: 28px;
}
</style>
<style>
.el-button + .el-button {
  margin-left: 0px;
}
</style>


